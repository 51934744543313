import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Componentes
import { PrivacyPolicyComponent } from './../components/privacy-policy/privacy-policy.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([
      { path: 'privacy_policy.html', component: PrivacyPolicyComponent },
      { path: '', redirectTo: 'privacy_policy.html', pathMatch: 'full' },
      { path: '**', redirectTo: 'privacy_policy.html', pathMatch: 'full' }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule { }
